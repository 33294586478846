/**
 * @file Auth modal that is used
 * @author Alwyn Tan
 */

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import useCurrentUser from '#/hooks/query/useCurrentUser'
import PhoneLoginModal from './PhoneLoginModal'
import OTPModal from './OTPModal'
import NameModal from './NameModal'

const Container = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Backdrop = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000cc;
`

type AuthModalPresentOptions = {
  phoneLoginSubtitle?: string
  nameSubtitle?: string
  backdropDisabled?: boolean
}

const AuthModal = forwardRef((props, ref) => {
  const { data: currentUser } = useCurrentUser()
  const [backdropDisabled, setBackdropDisabled] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [phoneLoginSubtitle, setPhoneLoginSubtitle] = useState('')
  const [nameSubtitle, setNameSubtitle] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [otp, setOTP] = useState('')
  const authCBRef = useRef(() => {})

  useImperativeHandle(ref, () => ({
    present: (
      cb: () => void,
      {
        phoneLoginSubtitle: pls = '',
        nameSubtitle: ns = '',
        backdropDisabled: bd = false,
      }: AuthModalPresentOptions = {}
    ) => {
      setBackdropDisabled(bd)
      setIsActive(true)
      authCBRef.current = cb
      setPhoneLoginSubtitle(pls)
      setNameSubtitle(ns)
    },
    dismiss: () => {
      setIsActive(false)
    },
  }))

  useEffect(() => {
    // decide when to trigger auth callback and turn off isActive
    if (isActive && currentUser && currentUser.name) {
      if (authCBRef.current) authCBRef.current()

      // reset start
      authCBRef.current = () => {}
      setBackdropDisabled(false)
      setIsActive(false)
      setPhoneNumber('')
      setOTP('')
      setPhoneLoginSubtitle('')
      setNameSubtitle('')
      // reset end
    }
  }, [isActive, currentUser])

  const handlePhoneLoginComplete = (value: string) => {
    setPhoneNumber(value)
  }

  const handleOTPBackClick = () => {
    setPhoneNumber('')
  }

  const handleOTPComplete = (value: string) => {
    setOTP(value)
  }

  const handleBackdropClick = () => setIsActive(false)

  const renderModals = () => {
    if (!currentUser && !phoneNumber)
      return (
        <PhoneLoginModal
          subtitle={phoneLoginSubtitle}
          onComplete={handlePhoneLoginComplete}
        />
      )

    if (!currentUser && phoneNumber && !otp)
      return (
        <OTPModal
          phoneNumber={phoneNumber}
          onBack={handleOTPBackClick}
          onComplete={handleOTPComplete}
        />
      )

    if (currentUser && !currentUser.name)
      return <NameModal subtitle={nameSubtitle} />

    return null
  }

  return (
    <AnimatePresence>
      {isActive && (
        <Container>
          <Backdrop
            style={{ pointerEvents: backdropDisabled ? 'none' : 'all' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.15 }}
            onClick={handleBackdropClick}
          />
          {renderModals()}
        </Container>
      )}
    </AnimatePresence>
  )
})

export default AuthModal
