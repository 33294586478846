/**
 * @file Request Login Hook
 * @author Alwyn Tan
 */

import { useMutation } from '@tanstack/react-query'
import api from '#/utils/api'

const postRequestLogin = async (phoneNumber: string) =>
  api
    .post('user/request-login', {
      json: { phoneNumber },
      credentials: 'include',
    })
    .json()

const useRequestLogin = () =>
  useMutation(async (phoneNumber: string) => postRequestLogin(phoneNumber))

export default useRequestLogin
