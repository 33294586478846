/**
 * @file Reusable Modal
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { DarkerGray } from '#/constants/colors'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
  position: fixed;
  bottom: 20px;
  left: 16px;
  right: 16px;
  background-color: ${DarkerGray};
  border-radius: 20px;
  padding: 20px;
  z-index: 1;
`

type Props = { children: React.ReactNode }

const Modal = ({ children }: Props) => (
  <Container
    initial={{ y: 200 }}
    animate={{ y: 0 }}
    exit={{ y: 200 }}
    transition={{ ease: 'easeInOut', duration: 0.15 }}
  >
    {children}
  </Container>
)

export default Modal
