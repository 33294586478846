/**
 * @file Global Styles for the application
 * @author Alwyn Tan
 */

import { Purple } from '#/constants/colors'
import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html,body{
    height: 100%;
    -webkit-overflow-scrolling : touch !important;
    overflow: auto !important;
  }

  html {
    box-sizing: border-box;
    background: ${({ theme }) => theme.Background}
  }

  body  {
    font-size: 15px; /* base font size, our p is 15px */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  a {
    letter-spacing: 0.025em;
    text-decoration: none;
    color: ${Purple};
  }

  * {
    box-sizing: border-box;
  }
`

export default GlobalStyle
