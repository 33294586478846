/**
 * @file Update User Hook
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from '@tanstack/react-query'
import useCurrentUser from '#/hooks/query/useCurrentUser'
import { userKeys } from '#/query-key-factory'
import api from '#/utils/api'

type UserDetails = { name: string }

const putUpdateUser = async (id: string, details: UserDetails) =>
  api.put(`user/${id}`, { json: details }).json()

const useUpdateUser = () => {
  const queryClient = useQueryClient()
  const { data: currentUser } = useCurrentUser()

  return useMutation(
    async (details: UserDetails) =>
      putUpdateUser(currentUser?.id || '', details),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(userKeys.me())
      },
    }
  )
}

export default useUpdateUser
