/**
 * @file Root Wrapper (for global stuff that doesnt rely on routes & won't rerender) Good article to read on: https://markoskon.com/wrap-root-element-vs-wrap-page-element/
 * @author Alwyn Tan
 */

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Toaster } from 'react-hot-toast'
import GlobalProvider from './contexts/global-context'
import theme from './styles/theme'
import './styles/fonts.css'

dayjs.extend(utc)
dayjs.extend(timezone)

// todo: this implementation has to change when switching cities
dayjs.tz.setDefault('America/New_York')

const queryClient = new QueryClient()

type Props = { children: React.ReactNode }

const RootWrapper = ({ children }: Props) => (
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>{children}</GlobalProvider>
    </QueryClientProvider>
    <Toaster
      toastOptions={{
        style: {
          boxShadow: '0px 2px 8px #000000',
          backgroundColor: '#262627',
          borderRadius: 100,
          color: 'white',
          fontFamily: 'Inter',
          zIndex: 99999,
        },
      }}
    />
  </ThemeProvider>
)

export default RootWrapper
