/**
 * @file Hook to receive current user data from the server
 * @author Alwyn Tan
 */

import api from '#/utils/api'
import { useQuery } from '@tanstack/react-query'
import { userKeys } from '#/query-key-factory'

type CurrentUserResponseJSON = {
  user: User
}

const getCurrentUser = async () =>
  api.get('user/me').json<CurrentUserResponseJSON>()

const useCurrentUser = ({ enabled = true } = {}) =>
  useQuery(userKeys.me(), async () => getCurrentUser(), {
    enabled,
    select: data => data?.user,
  })

export default useCurrentUser
