/**
 * @file All keys for react query
 * @author Alwyn Tan
 */

export const userKeys = {
  all: ['user'],
  me: () => [...userKeys.all, 'me'],
  details: () => [...userKeys.all, 'detail'],
  detail: (username: string) => [...userKeys.details(), username],
}

export const affiliateLinkKeys = {
  all: ['affiliateLink'],
  lists: () => [...affiliateLinkKeys.all, 'list'],
  list: (filter = {}) => [...affiliateLinkKeys.lists(), filter],
  details: () => [...affiliateLinkKeys.all, 'detail'],

  // 2 ways:
  // 1: shortID (we'll only every query for this in SSR, never client)
  // 2: my affiliate link
  // todo: refactor this in the future
  detail: ({ shortID, dropID }: { shortID?: string; dropID?: string }) => [
    ...affiliateLinkKeys.details(),
    shortID,
    dropID,
  ],
}

export const dealKeys = {
  all: ['deal'],
  details: () => [...dealKeys.all, 'detail'],
  detail: (dealID: string) => [...dealKeys.details(), dealID],
}

export const dealClaimKeys = {
  all: ['dealClaim'],
  lists: () => [...dealClaimKeys.all, 'list'],
  list: (filter = {}) => [...dealClaimKeys.lists(), filter],
}

export const dropKeys = {
  all: ['drop'],
  details: () => [...dropKeys.all, 'detail'],
  detail: (id: string) => [...dropKeys.details(), id],
}
