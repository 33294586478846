/**
 * @file Text Input compontn
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { PatternFormat } from 'react-number-format'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { AnimatePresence, motion } from 'framer-motion'

interface ContainerProps {
  error?: boolean
}

const Container = styled.div<ContainerProps>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  height: 62px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff1a;
  color: white;
  padding: 0 16px;
  transition: 0.3s ease border-radius, 0.3s ease border-color;
  border: 4px solid transparent;
  border-color: ${({ error, theme }) => (error ? theme.Error : 'transparent')};
  border-radius: ${({ error }) => (error ? '8px 8px 0 0' : '8px')};

  > input {
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    width: 100%;
    color: inherit;
    outline: none;
    border: 0;
    background-color: transparent;
    display: flex;
  }
`

const Error = styled(motion.div)`
  background-color: ${({ theme }) => theme.Error};
  color: white;
  border-radius: 0 0 8px 8px;
  text-align: left;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`

const ErrorMessage = styled(motion.span)`
  padding: 5px 15px 8px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Inter';
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
`

type Props = {
  onChange?: (value: string) => void
}

const countryCode = '+1'

const PhoneInput = <TFieldValues extends FieldValues = FieldValues>({
  onChange = () => {},
  ...controllerProps
}: Props & UseControllerProps<TFieldValues>) => {
  const { field, fieldState } = useController({
    ...controllerProps,
    defaultValue: controllerProps.defaultValue || ('' as any), // todo: fix ts issues here
    rules: {
      ...controllerProps.rules,
      validate: value =>
        isMobilePhone(value || '') || 'Enter a valid US phone number',
    },
  })

  const hideError = field.value.trim().length < 17

  return (
    <>
      <Container error={!hideError && !!fieldState.error}>
        <PatternFormat
          format={`${countryCode} (###) ### ####`}
          mask=" "
          placeholder="Enter Phone Number"
          // eslint-disable-next-line react/jsx-props-no-spreading
          // {...field}
          onChange={e => {
            field.onChange(e.target.value)
            onChange(e.target.value)
          }}
        />
      </Container>
      <AnimatePresence>
        {!hideError && fieldState.error && (
          <Error
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 30, opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ ease: 'easeOut', duration: 0.2 }}
          >
            <ErrorMessage
              initial={{ y: -60 }}
              animate={{ y: 0 }}
              exit={{ y: -60 }}
            >
              {fieldState.error?.message || 'Required'}
            </ErrorMessage>
          </Error>
        )}
      </AnimatePresence>
    </>
  )
}

export default PhoneInput
