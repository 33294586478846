/**
 * @file Spinner Loading component
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const StyledSpinner = styled(motion.div)`
  width: 20px; /* the size */
  padding: 2.5px; /* the border */
  background: white; /* the color */
  aspect-ratio: 1;
  border-radius: 50%;
  mask: conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  box-sizing: border-box;
  animation: r 2s linear infinite;
`

type Props = {
  style?: React.CSSProperties
}

const Spinner = ({ style }: Props) => (
  <StyledSpinner
    style={style}
    animate={{ rotate: 360 }}
    transition={{ repeat: Infinity, duration: 0.8, ease: 'linear' }}
  />
)

export default Spinner
