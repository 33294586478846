/**
 * @file Page Wrapper (for stuff that relies on needing route context) Good article to read on: https://markoskon.com/wrap-root-element-vs-wrap-page-element/
 * @author Alwyn Tan
 */

import React from 'react'
import GlobalStyle from './styles/globalStyle'

type Props = {
  children: React.ReactNode
}

const PageWrapper = ({ children }: Props) => (
  <>
    <GlobalStyle />
    {children}
  </>
)

export default PageWrapper
