/**
 * @file Login Hook
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from '#/utils/api'
import toast from 'react-hot-toast'
import { userKeys } from '#/query-key-factory'

type LoginMutationParams = {
  phoneNumber: string
  code: string
}

type LoginResponseJSON = {
  user: { id: string; fcmTokens?: string[] }
  accessToken: string
}

const postLogin = async ({ phoneNumber, code }: LoginMutationParams) =>
  api
    .post('user/login', { json: { phoneNumber, code }, credentials: 'include' })
    .json<LoginResponseJSON>()

const useLogin = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ phoneNumber, code }: LoginMutationParams) => {
      const { accessToken, user } = await postLogin({ phoneNumber, code })

      // todo, shift this down to the callbacks...
      if (accessToken) delete user.fcmTokens

      return { ...user, accessToken }
    },
    {
      onMutate: () => {
        const toastID = toast.loading('Loading')
        return { toastID }
      },
      onError: (result, variables, context) => {
        toast.error('Error Requesting Login', { id: context?.toastID })
      },
      onSuccess: (user, variables, context) => {
        toast.success('Logged In!', { id: context?.toastID })
        window.analytics.identify(user.id, { id: user.id })
        queryClient.invalidateQueries(userKeys.me())
      },
    }
  )
}

export default useLogin
