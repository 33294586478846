/**
 * @file Ky api helper
 * @author Alwyn Tan
 */

import ky from 'ky'

let accessToken: string = ''

type SilentLoginJSON = {
  user: { id: string; fcmTokens?: string[] }
  accessToken: string
}

const api = ky.extend({
  prefixUrl: '/api',
  // only allow retry once for resetting the silent login token
  retry: {
    limit: 1,
    methods: ['get', 'put', 'post', 'delete', 'options'],
    statusCodes: [401, 403, 408, 413, 429, 500, 502, 503, 504],
  },
  hooks: {
    beforeRequest: [
      async request => {
        const hasRefreshToken = document.cookie.indexOf('rtExists=') !== -1
        const isLoginRequest =
          request.url.endsWith('/api/user/silent-login') ||
          request.url.endsWith('/api/user/login')

        if (!accessToken && hasRefreshToken && !isLoginRequest) {
          const resp = await ky
            .post('/api/user/silent-login', {
              credentials: 'include',
              throwHttpErrors: false,
            })
            .json<SilentLoginJSON>()

          accessToken = resp?.accessToken
          window.analytics.identify(resp.user.id, { id: resp.user.id })
        }

        if (accessToken)
          request.headers.set('Authorization', `Bearer ${accessToken}`)
      },
    ],
    afterResponse: [
      (request, options, response) => {
        if (response.status === 403 || response.status === 401) {
          accessToken = ''
          window.analytics.reset()
        }
      },
    ],
  },
})

export default api
