/**
 * @file Context for global settings, particularly triggering app-specific modals, eg. auth
 * @author Alwyn Tan
 */

import React, { createContext, useContext, useMemo, useRef } from 'react'
import AuthModal from '#/components/organisms/auth/AuthModal'

type AuthModalPresentOptions = {
  phoneLoginSubtitle?: string
  nameSubtitle?: string
  backdropDisabled?: boolean
}

type TGlobalContext = {
  authModalRef: React.RefObject<{
    present: (cb?: () => void, options?: AuthModalPresentOptions) => void
    dismiss: () => void
  }>
}

type GlobalProviderProps = {
  children: React.ReactNode
}

const GlobalContext = createContext<TGlobalContext>({
  authModalRef: { current: null },
})

const useAuth = () => {
  const context = useContext(GlobalContext)

  if (context === undefined)
    throw new Error('useAuth was called outside of its Provider')

  // exposes functions to trigger authentication to show
  return {
    presentAuthModal: (cb?: () => void, options?: AuthModalPresentOptions) =>
      context.authModalRef?.current?.present(cb, options),
    dismissAuthModal: () => context.authModalRef?.current?.dismiss(),
  }
}

const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const authModalRef = useRef(null)
  const value = useMemo(() => ({ authModalRef }), [])

  return (
    <GlobalContext.Provider value={value}>
      <AuthModal ref={authModalRef} />
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
export { useAuth }
