import React from 'react'
import RootWrapper from './src/RootWrapper'
import PageWrapper from './src/PageWrapper'

export const wrapRootElement = ({ element }) => (
  <RootWrapper>{element}</RootWrapper>
)

export const wrapPageElement = ({ element }) => (
  <PageWrapper>{element}</PageWrapper>
)
